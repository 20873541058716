import React from 'react'
import moment from 'moment'
import { Box, Text, Badge } from '@chakra-ui/core'
import Image from './Image'

const ProductCard = ({
  rank,
  title,
  subtitle,
  image,
  description,
  date,
  price,
  link = '/',
  ...rest
}) => (
  <Box
    maxW="sm"
    borderWidth="1px"
    rounded="lg"
    overflow="hidden"
    {...rest}
    mb="30px"
  >
    <a href={link} rel="nofollow" target="_blank">
      <Box position="relative" height="250px" overflow="hidden">
        <Image
          aspectRatio={1.38}
          src={image}
          sizes={[153, 306, 459, 612]}
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          objectFit="cover"
        />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          p={4}
          backgroundImage="linear-gradient(rgba(5,15,33,.1),rgba(5,15,33,.7))"
        >
          <Box>
            <Box
              rounded="50%"
              width="40px"
              height="40px"
              bg="#6c6c6c"
              lineHeight="40px"
              textAlign="center"
              marginLeft="auto"
              fontSize="18px"
              color="white"
              boxShadow="0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)"
            >
              {rank}
            </Box>
          </Box>

          <Box marginTop="auto">
            <Text
              mt={2}
              fontSize="xl"
              fontWeight="semibold"
              lineHeight="short"
              as="h1"
              color="white"
            >
              {title}
            </Text>
            <Text color="white" as="small">
              {subtitle}
            </Text>
          </Box>
        </Box>
      </Box>
    </a>
    <Box p="2">
      <a href={link} rel="nofollow" target="_blank">
        <Badge
          width="100%"
          display="flex"
          py={1}
          px={3}
          fontSize={16}
          textTransform="unset"
          fontWeight="normal"
        >
          <Text as="span">${price}</Text>
          <Text as="span" marginLeft="auto">{`as of ${date ||
            moment().format('ll')}`}</Text>
        </Badge>
      </a>
      <Text mt={2}>
        <p dangerouslySetInnerHTML={{ __html: description }} />
      </Text>
    </Box>
  </Box>
)

export default ProductCard
