import React from 'react'
import ProductCard from './ProductCard'
import styled from '@emotion/styled'

const StyledProductContainer = styled.div`
  @media screen and (min-width: 740px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
  }
`

const ProductContainer = ({ products = [] }) => {
  return (
    <StyledProductContainer>
      {products.map((product, idx) => (
        <ProductCard
          rank={idx + 1}
          image={product.image}
          link={product.link}
          title={product.title}
          subtitle={product.subtitle}
          price={product.price}
          date={product.date}
          description={product.description}
        />
      ))}
    </StyledProductContainer>
  )
}

export default ProductContainer
