import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import ProductCard from './src/components/ProductCard'
import ProductContainer from './src/components/ProductContainer'

export const wrapRootElement = ({ element }) => (
  <MDXProvider components={{ ProductCard, ProductContainer }}>
    {element}
  </MDXProvider>
)
